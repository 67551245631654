.my-form {
    /* background: hsl(224, 32%, 12%);
    background-image: conic-gradient(from 0deg at 50% 50%,
            blue,
            purple,
            purple,
            blue);
    background-blend-mode: multiply;
    color: white; */
    min-block-size: 100dvh;
    display: grid;
    place-items: center;
    max-height: 90vh;
}   

:root {
    --red: hsl(350, 70%, 55%);
    --teal: hsl(231, 70%, 62%);
    --white: hsl(334, 7%, 95%);
    --undefined: hsl(334, 7%, 95%);
    --borderFormEls: hsl(0, 0%, 10%);
    --bgFormEls: hsl(0, 0%, 14%);
    --bgFormElsFocus: hsl(0, 7%, 20%);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.container2 {
    font-size: 1rem;
    color: var(--undefined);
    background: hsl(221, 48%, 9%);
    padding: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 1rem;
    position: relative;
    transform-style: preserve-3d;
    transform:
        perspective(100rem) rotateY(var(--rotateY)) rotateX(var(--rotateX));
}

.tokenHead {
    /* color: var(--red); */
    color: #c488e0;
    text-shadow: 0 0 0.1em currentColor;
}

.my-form button {
    width: 50%;
    line-height: 1.5;
    padding: 15px 10px;
    border: 1px solid var(--borderFormEls);
    color: var(--white);
    background: var(--bgFormEls);
    transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
      transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.gridGrid-2 {
    text-align: right;
    color: var(--white);

    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    vertical-align: top;
    text-shadow: 0 0 0.1em currentColor;
}

/* textarea {
    border-radius: 0.1rem solid blueviolet;
    margin-left: ;
} */

.selectDiv{
    padding: 0.5rem;
    border-radius: 2rem;
    border: 0.1rem solid blueviolet;
}

.container2 label {
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.container2 input, 
.container2 textarea{
    width: 300px;
    margin-left: 1rem;
    border-radius: 2rem;
    padding: 0.5rem;
    border: 0.1rem solid blueviolet;
}

.container2::before,
.container2::after {
    content: "";
    position: absolute;
    border-radius: inherit;
}

.container2::before {
    inset: 0.75rem;
    border-radius: inherit;
    background: black;
    z-index: -1;
    transform: translateZ(-50px);
    filter: blur(15px);
    opacity: 0.5;
}

.container2::after {
    z-index: -2;
    inset: -1rem;
    background: linear-gradient(-45deg, var(--red), var(--teal));
    transform: translateZ(-50px);
}

.btn-three {
    color: #FFF;
    transition: all 0.5s;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius:2rem;
  }
  .btn-three::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* background-color: rgba(255,255,255,0.1); */
    transition: all 0.3s;
  }
  .btn-three:hover::before {
    opacity: 1 ;
    transform: scale(0.5,0.5);
  }
  .btn-three::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    transition: all 0.3s;
    /* border: 1px solid rgba(255,255,255,0.5); */
    transform: scale(1.2,1.2);
    color: white;
  }
  .btn-three span {
    position: relative;
    z-index: 2;
  }
  .btn-three:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }



  @media only screen and (max-width: 1000px) {
    .container2 input, 
.container2 textarea{
    width: 200px;
    margin-left: 1rem;
    border-radius: 2rem;
    padding: 0.5rem;
    border: 0.1rem solid blueviolet;
}
  }
  