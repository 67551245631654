/* @import "https://unpkg.com/open-props";
@import "https://unpkg.com/open-props/normalize.min.css"; */

.brochuremedia-scroller {
  --_spacer: var(--size-3);
  display: grid;
  gap: var(--_spacer);
  grid-auto-flow: column;
  grid-auto-columns: 100%;

  padding: 0 var(--_spacer) var(--_spacer);

  overflow-x: auto;
  overscroll-behavior-inline: contain;
}

.brochuremedia-scroller--with-groups {
  grid-auto-columns: 80%;
}

.brochuremedia-group {
  display: grid;
  gap: var(--_spacer);
  grid-auto-flow: column;
}
.brochuremedia-element {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .brochuremedia-element {


  display: grid;
  grid-template-rows: min-content;
  gap: var(--_spacer);
  padding: var(--_spacer);
  background: var(--surface-2);
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
} */

.brochuremedia-element > img {
  width: 80vw;
  height: 80vh;

  /* aspect-ratio: 16 / 9; */
  object-fit: cover;
}

.brochuresnaps-inline {
  height: 100vh;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--_spacer, 1rem);
}

.brochuresnaps-inline > * {
  scroll-snap-align: start;
  background-image: linear-gradient(
    0deg,
    #645f94 0%,
    #463f85 50%,
    #25316d 50%,
    #25316d 100%
  );
}

/* general styling */
