@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');



:root {
  --surface-color: #fff;
  --curve: 40;
}



.cardscon {

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.cardcon {
  position: relative;
  display: block;
  height: 100%;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__imagecon {      
  width: 100%;
  min-height: 300px;
}

.card__overlaycon {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: #485cb7;      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.cardcon:hover .card__overlaycon {
  transform: translateY(0);
}

.card__headercon {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: #aee0f5 ;
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arccon {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arccon path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.cardcon:hover .card__headercon {
  transform: translateY(0);
}

.card__thumbcon {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__titlecon {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #180022;
}

.card__taglinecon {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__statuscon {
  font-size: .8em;
  color: #1f0129;
}

.card__descriptioncon {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    