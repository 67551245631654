@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');




.aNav3d{
  text-decoration:none;
  color:transparent;
  padding:0px 5px;
}

.glowNav3d{
  width:300px;
  height:300px;
  background:radial-gradient(rgba(255,231,113,0.5),rgba(255,255,255,0.5) 5%,rgba(255,255,255,0.2) 20%,rgba(255,255,255,0.1) 30%,rgba(255,255,255,0) 60%);
  position:absolute;
  margin:auto;
  left:0;
  right:0;
  top:22%;

  border-radius:50%;
}

.pole-containerNav3d{
  animation:ghostly-turn 2s;
  position:absolute;
  bottom:10%;
  width:100%;
  height:80%;
  transform-style: preserve-3d;  
}

.poleNav3d{
  background:linear-gradient(transparent,black),linear-gradient(
90deg,rgba(88,52,36,0.4),rgba(35,19,12,0.4)),linear-gradient(
90deg,rgba(88,52,36,0.4),rgba(35,19,12,0.4)),radial-gradient(
rgb(128,84,65),#23130c);
  background-size:100% 100%,5px 5px,10px 10px,100% 100%;
  background-position:2px 5px,0px 0px,0px 0px;
  height:110%;
  width:20px;
  position:absolute;
  margin:auto;
  left:0;
  right:0;
  border-radius:20% 20% 0px 0px;
}

.signNav3d{
    background:linear-gradient(rgba(88,52,36,0.5),rgba(35,19,12,0.5)),radial-gradient(
rgb(128,84,65),#23130c,black);
  background-size:10px 10px,100% 100%;
  width:200px;
  padding:15px 0px;
  clip-path: polygon(0% 20%, 85% 20%, 100% 50%, 85% 80%, 0% 80%);
  -webkit-clip-path: polygon(0% 20%, 85% 20%, 100% 50%, 85% 80%, 0% 80%);
  position:absolute;
  margin:auto;
  left:20px;
  right:0;
  color: transparent;
  font-size: 34px;
  text-align: center;
  font-family: 'Indie Flower', cursive;
  text-shadow: 0px 0px 2px #ffffff,0px 0px 1px rgb(119, 95, 122);
  border-radius:50px;
  backface-visibility: hidden;
}

.signNav3d{
  cursor:pointer;
  transition:0.5s;
}

.sign-1{
  transform:rotate(-10deg);
  
}

.sign-1:hover{
  transform:rotate(10deg);
}

.sign-2{
  top:10%;
  transform: rotateY(50deg);
}
.sign-2:hover{
  transform: rotateY(50deg) rotate(-10deg);
}

.sign-3{
  top:20%;
}

.sign-3:hover{
   transform: rotateY(30deg);
}

.sign-4{
    top:30%;
    transform:rotate(5deg);
  }
  
  .sign-4:hover{
     transform: rotateY(30deg);
  }
  .sign-5{
    top:40%;
    transform:rotate(-3deg);
  }
  .sign-5:hover{
     transform: rotate(10deg);
  }
  .sign-6{
    top:50%;
    transform: rotateY(5deg);
  }
  .sign-6:hover{
    transform: rotateY(50deg) rotate(-10deg);
  }
  .sign-7{
    top:60%;
    transform:rotate(10deg);
  }
  .sign-7:hover{
     transform: rotateY(30deg);
  }
  .sign-8{
    top:70%;
    transform:rotate(-10deg);
  }
  .sign-8:hover{
     transform: rotateY(30deg);
  }
  .sign-9{
    top:80%;
    transform:rotate(0deg);
  }
  .sign-9:hover{
     transform: rotateY(30deg);
  }
  .sign-10{
    top:90%;
    transform:rotate(-10deg);
  }
  .sign-10:hover{
     transform: rotateY(30deg);
  }
  

@keyframes ghostly-turn{
  100%{
    transform: rotateY(360deg);
  }
}
