.app__events {
  padding: 5rem 3rem;
  background: radial-gradient(#004, #000);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.events__img {
  width: 100%;
  height: 100%;
}

/* FRAME */

.events__box {
  --s: 10px; /* control the size */
  padding: var(--s);
  border: calc(2 * var(--s)) solid #0000;
  outline: 1px solid var(--color-golden);
  outline-offset: calc(-1 * var(--s));
  background: conic-gradient(
    from 90deg at 1px 1px,
    #0000 25%,
    var(--color-golden) 0
  );
}

/* Register Button */
.btn__wrapperReg {
  margin: 2rem 0 1rem 0;
  position: relative;
  width: fit-content;
}
.btn__register {
  display: block;
  width: 250px;
  height: 50px;
  font-weight: bold;
  text-decoration: none;

  /* Gradient Color */
  background: radial-gradient(#000, #004);

  text-align: center;
  color: var(--color-golden);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid var(--color-golden);
  border-radius: 10px;
  transition: all 0.35s;
}
.icon__RegBtn {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  border-radius: 10px;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.35s;
}
.icon__RegBtn svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: var(--color-golden);
  z-index: -1;
  transition: all 0.35s;
}
.btn__register:hover {
  width: 200px;
  border: 3px solid var(--color-golden);
  background: transparent;
  color: var(--color-golden);
}
.btn__register:hover + .icon__RegBtn {
  border: 3px solid var(--color-golden);
  right: -25%;
  z-index: 1;
}
/* Register Button */
