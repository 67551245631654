body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {display:none;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --color-golden: #dcca87;
  --color-black: #0c0c0c;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 19px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

@media screen and (min-width: 2000px) {
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }
}

@media screen and (max-width: 650px) {
  .p__cormorant {
    font-size: 18px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
