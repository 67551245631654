
.sidenav {
    height: 95%;
    width: 300px;
    position: fixed;
   
    top: 2.5%;
    right: 0;
    background-color: rgba(1, 2, 26, 0.7);
    overflow-x: hidden;
    transition: 2s;
    padding-top: 60px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    animation: slideIn 2s;
    -webkit-transition: all 1s ease-in-out;
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  .mySidenav{
    width: 250px;
  }