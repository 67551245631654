.screen2-text{
    position: sticky;
    font-size: 1.5em;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 0 0;
    width: 100%;
    max-width: 600px;
    line-height: 1.5em;
}

.associateForm {
    position: absolute;
    z-index: 2;
    left: 25vw;
    top:0;
    width: 50vw;
    overflow: hidden;  
}

.associateForm .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 100px;
    color: white;
    
    /* margin-left: 50px; */
  }

  @media only screen and (max-width: 1000px) {
    .associateForm {
       
        left:0;
        top: 7vh;
        width: 100vw;
        
    }
    .associateForm .closebtn {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 100px;
        color: white;
        
        /* margin-left: 50px; */
      }
  }