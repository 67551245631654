.top_one {
  font-family: "Montserrat", sans-serif;
 
  font-size: 0.875rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bgmerch {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-image: url("../assets/merch/Purp_Forest.jpg");
  opacity: 0.8;
  /* background-color: #542061; */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.heading_h1 {
  font-family: "Fraunces", serif;
  font-size: 2.5rem;
  color: hsl(0, 0%, 100%);
}

.heading_h2 {
  text-align: left;
  letter-spacing: 0.15625rem;
  font-size: 1.3rem;
  color: hsl(0, 0%, 100%);
  font-weight: 1000;
}

.para {
  color: hsl(0, 29%, 97%);
  line-height: 1.7rem;
  text-align: left;
  font-size: 1.2rem;
}

.top-container {
  display: flex;
  margin: 2.5rem auto;
  padding: 0 1.5rem;
  align-self: center;
  padding-top: 5rem;
}

.grid {
  border-radius: 0.9375rem;
  background-color: rgba(0,0,0,0.5);
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 90rem;
  padding: 20px;
}

.image-container img {
  background-color: #fff;
  border-radius: 1rem;
  margin: 40px;
  max-width: 90%;
  display: inherit;
}

.content-container {
  color: #fff;
  /* background-color: #4b2e6b7c; */
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}




.customButton{
  
  color: #fff;
  padding: 15px 25px;
  border-radius: 100px;
  background-color: #674fa0;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}
.customButton:hover {
  background-color: #4C43CD;
}

.sizetable th{
  padding-right: 15px;
}
.linkcol {
  color: rgb(220, 132, 220);
}
/* Media Query */
@media only screen and (max-width: 39.375rem) {
  .heading_h1 {
    font-size: 1.5rem;
  }

  heading_h1,
  heading_h2,
  para,
  price-active,
  price-inactive,
  price-button {
    padding-bottom: 0.625rem;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .image-container img {
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
    border-bottom-left-radius: 0;
    max-height: 28.125rem;
    width: 100%;
    object-fit: cover;
  }

  .content-container {
    display: block;
  }

  .price-button {
    align-self: left;
    width: 100%;
    border-radius: 0.625rem;
  }
}
