.contactContainer {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding-top: 80px;
    transition: .5s ease-in-out;
}

.contactHeading h1 {
    color: white;
    z-index: 5;
    font-size: 4rem;
}
.bgContact {
    position: fixed;
    z-index: -5;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        0deg,
        #645f94 0%,
        #463f85  50%,
        #25316d 50%,
        #25316d 100%
      );
      background-position: fixed;
}
.contactNav {
    position: fixed;
    width: 30%;
    height: 87%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    pointer-events: all;
}

.contactGrid {
    position: absolute;
    width: 70%;
    right: 0;
}

.ConHeading{
    padding-top: 20px;
}
.ConHeading h2 {
    color: rgb(132, 214, 234);
}

@media only screen and (max-width: 1200px) {
    .contactNav {
        display: none;
    }
    .contactGrid {
        position: absolute;
        width: 100%;
        
    }
}