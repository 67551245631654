/* implement a responsive container to show brochure-container with a light gradient as the background */

.brochure-container {
  background: linear-gradient(rgba(63, 9, 54, 0.5), rgba(54, 49, 49, 0.5));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: auto;
  padding: 5%;
}
/*.img-cont {
  height: inherit;
  width: inherit;
  position: relative;
} 
.slider-wrapper {
  height: 100%;
  width: 100%;
}
.carousel-wrapper {
  width: 100%;
  position: absolute;
  padding: 0% 5% 5% 5%;
}
.control-dots {
  height: 0;
}
.slide-selected {
  background-color: rgba(240, 248, 255, 0);
}

@media only screen and (max-width: 768px) {
  .brochure-container {
    height: 100%;
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
} */
@media only screen and (max-width: 768px) {
  .brochure-container {
    padding: 5%;
    align-items: center;
  }
  .alice-carousel {
    width: inherit;
  }
}
