.queries {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.queries:focus {
  outline: 0;
}

.queries {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.queries {
  align-items: center;
  background-image: linear-gradient(
    0deg,
    #645f94 0%,
    #463f85  50%,
    #25316d 50%,
    #25316d 100%
  );
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  position: relative;
  transform-style: preserve-3d;
  /* padding: 2rem; */
  padding-top: 7rem;
}

.container .card {
  align-items: center;
  background-color: #97d2ec;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  height: 400px;
  justify-content: center;
  margin: 2rem 20px;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
  width: 300px;
}

.container:hover .card,
.container:focus .card,
.container:active .card {
  transform: perspective(500px) rotateY(30deg);
}

.container .card:hover,
.container .card:focus,
.container .card:active {
  transform: perspective(500px) rotateY(0);
}

.container .card:hover ~ .card,
.container .card:focus ~ .card,
.container .card:active ~ .card {
  transform: perspective(500px) rotateY(-30deg);
}

.container .card h2 {
  background-color: #2196f3;
  border-radius: 50%;
  color: #ffffff;
  font-size: 3rem;
  height: 80px;
  line-height: 80px;
  text-align: center;
  transition: box-shadow 0.5s ease;
  width: 80px;
}

.container .card:hover h2,
.container .card:focus h2,
.container .card:active h2 {
  background-color: #5274f0;
  box-shadow: 0 0 0 150px #2196f3;
  color: #412555;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.queryImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
h4 {
  margin: 5px;
}
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
span {
  margin: 5px;
}
