.app__sponsors {
  min-height: 100vh;
  width: 100%;
}
.sponsors__left {
  height: 100vh;
  width: 35%;
  padding: 0 2.5rem 3rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
.sponsorsBg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url("../assets/sponsors/sponsors.png");
  opacity: 1;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;
}
.sponsors__left h1 {
  color: rgb(201, 193, 193);
  font-size: 4rem;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.sponsors__left p {
  color: rgb(231, 223, 223);
  font-weight: 400;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.sponsors__right {
  background: #be93c5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #7bc6cc,
    #be93c5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #7bc6cc,
    #be93c5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 6rem 1rem 1rem;
  width: 65%;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  right: 0;
}

.sponsors__imageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.sponsors__img {
  height: auto;
  width: 85%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  margin-bottom: 20px;
}

.sponsors__desc{
  font-weight: 600;
}

.sponsors__imageDiv:hover .sponsors__img {
  transform: scale(1.13);
}

@media (max-width:990px){
  .sponsors__left {
    width: 100%;
    position: relative;
    padding: 0 2.5rem 3rem;
  }
  .sponsors__right {
    width: 100%;
    position: relative;
    padding: 6rem 1rem 1rem;
  }
}
